import Cabecalho from '../../utils/cabecalho';
import Menu from '../../utils/menu';
import Rodape from '../../utils/rodape';
import image1 from '../../img/image1.jpg';
import image2 from '../../img/image2.jpg';
import image3 from '../../img/image3.jpg';
import { 
    Container, 
    Row,
    Col,
    Carousel,
    Button
} from 'react-bootstrap';
import Noticias from './noticias';

function Home() {
    return ( 
        <div>
            <Cabecalho />
            <Menu />
            <Carousel 
                className="mt-3">
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={image1}
                    alt=""
                    style={{ 
                        maxHeight: '40rem' }} 
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={image2}
                    alt=""
                    style={{ 
                        maxHeight: '40rem' }} 
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src={image3}
                    alt=""
                    style={{ 
                        maxHeight: '40rem' }} 
                    />
                </Carousel.Item>
            </Carousel>
            <Container fluid="md" >
                <Row>
                    <Col md={12}
                        className="text-center display-2 mt-4"
                        style={{ 
                            fontWeight: 'bold',
                            textColor: 'blue',
                            marginTop: '2rem',
                            marginBottom: '1.5rem' }}>
                        NOTÍCIAS
                    </Col>
                </Row>
            </Container>
            <Noticias />
            <Container fluid="md" >
                <Row>
                    <Col md={12}
                        className="text-center mt-4" >
                        <a href="/noticias">
                            <Button 
                                variant="outline-primary" >
                                Todas as Notícias
                            </Button>
                        </a>
                    </Col>
                </Row>
            </Container>
            <Rodape />
        </div>
    );
}

export default Home;