import logo from '../img/logo.jpg';
import { 
    Container, 
    Row,
    Col,
} from 'react-bootstrap';

function Cabecalho() {
    return ( 
        <Container fluid="md" >
            <Row>
                <Col md={4} className="text-center mt-3">
                    <img
                        alt=""
                        src={logo}
                        width="350"
                        height="200"/>
                </Col>
                <Col md={4}>
                </Col>
                <Col md={4}
                    className="text-center display-5 mt-4"
                    style={{ 
                        fontWeight: 'bold' }}>
                    INSTITUTO DE DESENVOLVIMENTO SOCIAL ITAQUERÊ
                </Col>
            </Row>
        </Container>
    );  
}

export default Cabecalho;