import { 
    useState,
    useEffect, 
} from 'react';
import axios from 'axios';
import * as constants from '../../utils/constants';

function Balancetes() {

    let key = 0;

    const URL_LISTAR_SECAO = constants.URL_BASE + constants.SECAO;
    const URL_LISTAR_DOCUMENTO = constants.URL_BASE + constants.DOCUMENTO;
    const URL_DOC = constants.URL_BASE + constants.LOCAL_DOC;

    const [secoes, setSecoes] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [carregarBalancetes, setCarregarBalancetes] = useState(true);

    useEffect(() => {

        async function obterSecao(){
            let { data } = await axios.get(URL_LISTAR_SECAO);
            setSecoes(data.data);
        }

        async function obterDocumento(){
            let { data } = await axios.get(URL_LISTAR_DOCUMENTO);
            setDocumentos(data.data);
        }

        if(carregarBalancetes){
            obterSecao();
            obterDocumento();
            setCarregarBalancetes(false);
        }
    }, [carregarBalancetes]);

    function gerarItemSecao(codigo, nivel){
        let listSecao = [];
        listSecao = secoes.filter(secao => {
            return ((parseInt(secao.nivel) === nivel) && (parseInt(secao.codparente) === codigo));
        });
        let items = [];
        listSecao.map(secao => {
            items.push(gerarDescrSecao(secao));
            items.push(gerarSecao(secao));
        });
        return(items);
    }

    function gerarSecao(secao){
        return(
            <ul 
                key={key++}
                style={{ 
                    listStyle: 'none' }} >
                {gerarDocumento(secao)}
                {gerarItemSecao(parseInt(secao.codigo), (parseInt(secao.nivel) + 1))}
            </ul>
        );
    }

    function gerarDocumento(secao){
        let items = [];
        documentos.map(documento => {
            if(parseInt(documento.secao) === parseInt(secao.codigo)){
                items.push(gerarDescrDocumento(documento));
            }
        });
        return(items);
    }

    function textoSecao(nivel) {
        if(nivel === 1){
            return 'mb-1 display-4 text-primary';
        }
        if(nivel === 2){
            return 'mb-1 display-5 text-info';
        }
        if(nivel === 3){
            return 'mb-1 display-6 text-secondary';
        }
        if(nivel === 4){
            return 'mb-1 font-weight-bold';
        }
    }

    function gerarDescrSecao(secao) {
        return(
            <li 
                key={key++} 
                className={textoSecao(parseInt(secao.nivel))}
                style={{ 
                    listStyle: 'none' }}>
                {secao.descricao}
            </li>
        );
    }

    function gerarDescrDocumento(doc){
        return(
            <li 
                key={key++} 
                className="mb-1"
                style={{ 
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    listStyle: 'none', }} >
                <a href={URL_DOC + doc.documento} target="_blank"
                    style={{ 
                        textDecoration: 'none', 
                        color: '#000' }} >
                    {doc.descricao}
                </a>
            </li>
        );
    }

    return (
        <div> 
            {gerarItemSecao(0, 1)}
        </div>
    );
}

export default Balancetes;