import Cabecalho from '../../utils/cabecalho';
import Menu from '../../utils/menu';
import Rodape from '../../utils/rodape';
import { 
    Container, 
    Row,
    Col,
} from 'react-bootstrap';
import Balancentes from './balancentes';

function Transparencia() {
    return ( 
        <div>
            <Cabecalho />
            <Menu />
            <Container fluid="md" >
                <Row>
                    <Col md={12}
                        className="display-3 mt-4"
                        style={{ 
                            fontWeight: 'bold',
                            textColor: 'blue',
                            marginTop: '2rem',
                            marginBottom: '1.5rem' }}>
                        Transparência
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Balancentes />
                    </Col>
                </Row>
            </Container>
            <Rodape />
        </div>
     );
}

export default Transparencia;