export const URL_BASE = 'http://www.institutoitaquere.org.br/restful/';
// export const URL_BASE = 'http://localhost/restful/';
export const DOCUMENTO = 'documento';
export const SECAO = 'secao';
export const USUARIO = 'usuarios';
export const NOTICIA = 'noticias';
export const GALERIA = 'galeria';
export const VIDEO = 'video';
export const LOCAL_IMG = 'img/';
export const LOCAL_DOC = 'doc/';
export const LOCAL_VIDEO = 'video/';
// export const BASE_NAME = '/siteqa';
export const BASE_NAME = '';