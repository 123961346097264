import Cabecalho from '../../utils/cabecalho';
import Menu from '../../utils/menu';
import Rodape from '../../utils/rodape';
import { 
    Container, 
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import GaleriaCasaDaLeitura from './galeira-casa-da-leitura';
import image from '../../img/casa-da-leitura.jpg';

function CasaDaLeitura() {
    return ( 
        <div>
            <Cabecalho />
            <Menu />
            <Container fluid="md" >
                <Row>
                    <Col md={12}
                        className="display-3 mt-4"
                        style={{ 
                            fontWeight: 'bold',
                            textColor: 'blue',
                            marginTop: '2rem',
                            marginBottom: '1.5rem' }}>
                        BIBLIOTECA CASA DA LEITURA ITAQUERÊ
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mb-4">
                    <Image
                        variant="top" 
                        src={image}
                        thumbnail />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>A <b>Biblioteca Casa da Leitura Itaquerê</b> representa um importante 
                            portal para a comunidade em relação ao acesso à informação, ao 
                            conhecimento, à cultura e ao lazer, contribuindo para a formação 
                            de cidadãos críticos e participantes. Sua implantação ocorreu no 
                            ano de 1990 e previu a constituição mista de uma biblioteca pública 
                            e escolar simultaneamente, deste modo, uma importante oportunidade 
                            que ela oferece à sociedade, é sua constituição como um centro 
                            irradiador de incentivo e fomento à leitura para todos os segmentos 
                            da sociedade. Consideram-se como fundamentos da biblioteca, a 
                            informação, a educação e a cultura, tendo como princípios: o apoio 
                            ao processo de ensino/aprendizagem; o estímulo à imaginação e 
                            criatividade; a promoção do conhecimento sobre a herança cultural e a 
                            viabilização ao acesso às informações da comunidade local.</p>
                        <p>O Instituto Itaquerê, através da Biblioteca Casa da Leitura Itaquerê, tem 
                            o propósito de incentivar a leitura, cativando nossa comunidade e escola, 
                            de forma natural, através da dinamização do seu ambiente e da implantação 
                            de novas atividades culturais.</p>
                        <p>O objetivo é, cada vez mais, transformar a biblioteca em um espaço ativo no 
                            processo de busca e construção do saber, trazendo a comunidade e a escola 
                            para este espaço e utilizando-o de forma significativa, para o enriquecimento 
                            do processo ensino-aprendizagem.</p>
                        <p>As atividades são realizadas de segunda-feira a sexta-feira, das 08:00 às 
                            11:00 horas em período matutino e das 13:00 às 16:00 horas em período 
                            vespertino.</p>
                        <p>Pensando em melhores condições para atender as crianças, adolescentes 
                            e toda a comunidade de Nova Europa, o Instituto Itaquerê investiu em 
                            melhorias na Casa da Leitura, que foi carinhosamente reestruturada através 
                            de doações e recentemente foi transferida para o prédio do CECOI, onde 
                            cuidadosamente, foi reinstalada, ganhando um novo espaço.</p>
                        <h2>Conteúdo programático:</h2>
                        <p>O projeto realiza diversas atividades culturais e educacionais visando 
                            o fomento a leitura, destacando-se: </p>
                        <ul>
                            <li><b>Festival de Leitura:</b> aberto a toda a comunidade, premia os 3 
                            participantes que leem mais livros em um determinado período.</li>
                            <li><b>Contação de Histórias:</b> aberto a toda a comunidade.</li>
                            <li><b>Parceria com as escolas:</b> consiste em um projeto de leitura 
                            realizado pelas escolas do município, onde os professores levam os alunos 
                            até a Casa da leitura e os estimulam a retirarem livros.</li>
                        </ul> 
                    </Col>
                </Row>
                <Row>
                    <Col md={12}
                        className="display-5 mt-4"
                        style={{ 
                            fontWeight: 'bold' }}>
                        Galeria de Fotos
                    </Col>
                </Row>
                <GaleriaCasaDaLeitura />
            </Container>
            <Rodape />
        </div>
     );
}

export default CasaDaLeitura;