import { 
    Col,
    Row,
    Image
} from 'react-bootstrap';
import { 
    LightgalleryProvider, 
    LightgalleryItem 
} from 'react-lightgallery';

function GaleriaCecoi() {

    const URL_IMG = 'http://www.institutoitaquere.org.br/img/cecoi-';
    const QTDE_IMG = 32;

    function addZeroes(num, len) {
        
        var numberWithZeroes = String(num);
        var counter = numberWithZeroes.length;
          
        while(counter < len) {
            numberWithZeroes = "0" + numberWithZeroes;
            counter++;
        }
      
      return numberWithZeroes;
    }

    function gerarColuna(imagem){
        return ( 
            <Col 
                md={3}
                key={imagem}>
                <LightgalleryItem src={URL_IMG + addZeroes(imagem, 2) + '.jpg'}>
                    <Image
                        key={imagem}
                        variant="top" 
                        src={URL_IMG + addZeroes(imagem, 2) + '.jpg'}
                        thumbnail 
                        style={{ cursor: 'pointer' }}/>
                </LightgalleryItem>
            </Col>
        );
    }

    function gerarColunas(linha){
        let items = [];
        let imagem = 0
        for(let i = 1; i <= 4; i++ ){
            imagem = (((linha - 1) * 4) + i);
            if(imagem <= QTDE_IMG){
                items.push(gerarColuna(imagem));
            }
        }
        return items;
    }

    function gerarLinha(linha){
        return (
            <Row className="mb-4"
                key={linha}>
                {gerarColunas(linha)}
            </Row>
        );
    }


    function gerarLinhas(){
        const numLinhas = Math.ceil(QTDE_IMG / 4);
        let items = [];
        for(let linha = 1; linha <= numLinhas; linha++ ){
            items.push(gerarLinha(linha))
        }
        return items;
    }

    return (
        <LightgalleryProvider>
            {gerarLinhas()}
        </LightgalleryProvider>
    );

}

export default GaleriaCecoi;