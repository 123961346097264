import Cabecalho from '../../utils/cabecalho';
import Menu from '../../utils/menu';
import Rodape from '../../utils/rodape';
import { 
    Container, 
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import GaleriaCecoi from './galeira-cecoi';
import image from '../../img/cecoi.jpg';

function Cecoi() {
    return ( 
        <div>
            <Cabecalho />
            <Menu />
            <Container fluid="md" >
                <Row>
                    <Col md={12}
                        className="display-3 mt-4"
                        style={{ 
                            fontWeight: 'bold',
                            textColor: 'blue',
                            marginTop: '2rem',
                            marginBottom: '1.5rem' }}>
                        CECOI – Centro de Convivência Itaquerê
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mb-4">
                    <Image
                        variant="top" 
                        src={image}
                        thumbnail />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>O Instituto de Desenvolvimento Social Itaquerê, através do 
                            Centro de Convivência Itaquerê (CECOI) em 22 anos, atendeu 
                            mais de 8.000 crianças e adolescentes com ações sócio educativas 
                            e culturais, integrado a escola e a comunidade.</p>
                        <p>É um projeto de cunho Socioeducacional, Cultural e Esportivo, 
                            com o objetivo de contribuir com o desenvolvimento integral de 
                            crianças e adolescentes do município e região. O serviço 
                            busca desenvolver ações integradas na organização, em conjunto 
                            com a comunidade, objetivando a melhoria da qualidade de vida 
                            das pessoas e famílias. </p>
                        <p>As atividades são realizadas de segunda-feira a sexta-feira, 
                            das 08:00 às 11:00 horas em período matutino e das 13:00 às 
                            16:00 horas em período vespertino, sendo que os alunos 
                            participam das atividades no contra turno escolar. Por 
                            semestre, antes da pandemia, o CECOI atendia em média 450 
                            crianças e adolescentes. </p>
                        <p>O Instituto Itaquerê também se preocupa com a alimentação 
                            dos alunos, desta forma, investe em uma alimentação mais 
                            saudável e reforçada. Todos os dias o Instituto oferece aos 
                            alunos lanche, suco e fruta.</p>
                        <br />
                        <p><b>O projeto realiza diversas atividades culturais e educacionais 
                            visando o fomento a leitura, destacando-se:</b></p>
                        <ul>
                            <li><b>Oficina de Qualificação Profissional:</b> Administração, 
                                informática, elétrica residencial, mecânica e RH</li>
                            <li><b>Oficina Cultural:</b> Dança, ballet, arte circense, 
                                teatro e violão</li>
                            <li><b>Oficina Esportiva:</b> Futebol</li>
                            <li><b>Oficina de Artesanato:</b> Pintura em tecido, artesanato 
                                em MDF e desenho artístico.</li>
                            <li><b>Oficina ambiental:</b> Horta Itaquerê</li>
                            <li><b>Oficina Pedagógica/Reforço Escolar:</b> Aulas de inglês, 
                                matemática, português e oficina de leitura</li>
                        </ul> 
                    </Col>
                </Row>
                <Row>
                    <Col md={12}
                        className="display-5 mt-4"
                        style={{ 
                            fontWeight: 'bold' }}>
                        Galeria de Fotos
                    </Col>
                </Row>
                <GaleriaCecoi />
            </Container>
            <Rodape />
        </div>
     );
}

export default Cecoi;