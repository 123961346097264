import {
    useState,
    useEffect,
} from 'react';
import {
    Row,
    Container,
} from 'react-bootstrap';
import ItensNoticias from './itens-noticias';
import axios from 'axios';
import * as constants from '../../utils/constants';

function Noticias() {

    const URL_LISTAR_NOTICIAS = constants.URL_BASE + constants.NOTICIA;

    const [noticias, setNoticias] = useState([]);
    const [carregarNoticias, setCarregarNoticias] = useState(true);

    useEffect(() => {

        async function obterNoticias(){
            try{
                let ordem = 'codigo DESC';
                const params = `?page=1&countpage=3&order=${ordem}&filter=`;
                let { data } = await axios.get(URL_LISTAR_NOTICIAS + params);
                if(data.status==='success'){
                    setNoticias(data.data);
                } else {
                    setNoticias([]);
                }
            } catch(err){
                setNoticias([]);
            }
        }

        if(carregarNoticias){
            obterNoticias();
            setCarregarNoticias(false);
        }
    }, [carregarNoticias]);

    return ( 
        <Container fluid="md" >
            <Row>
                <ItensNoticias 
                    noticias={noticias} />
            </Row>
        </Container>
     );
}

export default Noticias;