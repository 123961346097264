import { 
    Container, 
    Row,
    Col,
} from 'react-bootstrap';

function Rodape() {
    return ( 
        <div>
            <Container fluid className="mt-4"  >
                <Row>
                    <Col md={12}
                        className="text-center pt-2 pb-2" 
                        style={{ 
                            backgroundColor: 'blue',
                            color: 'white' }}>
                        © 2022 , Instituto Itaquerê - Todos os direitos reservados
                    </Col>
                </Row>
            </Container>
        </div>
     );
}

export default Rodape;