import PropTypes from 'prop-types';
import { 
    Col,
    Card,
} from 'react-bootstrap';
import * as constants from '../../utils/constants';

function ItensNoticias(props) {

    const URL_IMG = constants.URL_BASE + constants.LOCAL_IMG;

    return ( 
        props.noticias.map(noticia => 
            <Col 
                md={4}
                key={noticia.codigo}>
                <a href={'/noticia/' + noticia.codigo}
                    style={{ 
                        textDecoration: 'none', 
                        color: '#000' }} >
                    <Card 
                        key={noticia.codigo}>
                        <Card.Img 
                            variant="top" 
                            src={URL_IMG + noticia.capa}
                            style={{ 
                                minHeight: '20rem' }}  />
                        <Card.Body>
                            <Card.Title
                                className="text-center"
                                style={{ 
                                    fontWeight: 'bold',
                                    fontSize: '1.8rem', }}>
                                {noticia.titulo}
                            </Card.Title>
                            <Card.Text>
                                {noticia.conteudo.slice(0, 100) + '...'}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </a>
            </Col>
        )
    );
}

ItensNoticias.propTypes = {
    noticias: PropTypes.array.isRequired,
}

export default ItensNoticias;