import Cabecalho from '../../utils/cabecalho';
import Menu from '../../utils/menu';
import Rodape from '../../utils/rodape';
import { 
    Container, 
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import GaleriaHortaItaquere from './galeira-horta-itaquere';
import image from '../../img/horta-itaquere.jpg';

function HortaItaquere() {
    return ( 
        <div>
            <Cabecalho />
            <Menu />
            <Container fluid="md" >
                <Row>
                    <Col md={12}
                        className="display-3 mt-4"
                        style={{ 
                            fontWeight: 'bold',
                            textColor: 'blue',
                            marginTop: '2rem',
                            marginBottom: '1.5rem' }}>
                        PROJETO HORTA ITAQUERÊ
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mb-4">
                    <Image
                        variant="top" 
                        src={image}
                        thumbnail />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>Em 2016, em parceria com a Usina Santa Fé, o Instituto Itaquerê 
                            criou uma Horta Orgânica com a finalidade de proporcionar uma nova 
                            experiência e vivência para os alunos.</p>
                        <p>Além de produzir alimentos saudáveis, essa parceria promove a 
                            interação, vivência ambiental, senso de responsabilidade entre 
                            os alunos, noções de planejamento e cidadania.</p>
                        <p>Há 7 anos, os alunos do Instituto, colocam a mão na terra, 
                            plantam verduras, legumes e ervas aromáticas, regam a plantação, 
                            e todas as verduras da horta vão direto para as casas das 
                            crianças e adolescentes. Essa ação faz parte do projeto 
                            Horta Itaquerê, que tem como objetivo fortalecer o trabalho 
                            educacional voltado à formação de valores sociais, culturais 
                            e alimentares. </p>
                        <p>Tem como principal objetivo incentivar o senso de responsabilidade 
                            e sustentabilidade, e o intuito é ensinar conceitos de educação 
                            ambiental, nutricional e valorização do meio rural via 
                            horticultura, promovendo o contato com os alimentos e estimulando 
                            a adoção de hábitos de alimentação mais saudáveis.</p>
                        <p>Estimula a socialização e o trabalho em equipe, busca por 
                            outros assuntos relacionados à educação ambiental, 
                            conscientiza sobre a importância de uma alimentação saudável, 
                            interagi a criança com o solo e suas capacidades produtivas, 
                            assim como desenvolve técnicas de preparo, semeadura, plantio, 
                            irrigação e manutenção da horta instalada.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}
                        className="display-5 mt-4"
                        style={{ 
                            fontWeight: 'bold' }}>
                        Galeria de Fotos
                    </Col>
                </Row>
                <GaleriaHortaItaquere />
            </Container>
            <Rodape />
        </div>
     );
}

export default HortaItaquere;