import PropTypes from 'prop-types';
import { 
    Col,
    Row,
    Image
} from 'react-bootstrap';
import { LightgalleryProvider } from 'react-lightgallery';
import * as constants from '../../utils/constants';
import { Player } from 'video-react';

function GaleriaVideo(props) {

    const URL_VIDEO = constants.URL_BASE + constants.LOCAL_VIDEO;

    function gerarColuna(video){
        return ( 
            <Col md={6}
                key={video.codigo}>
                <Player
                    playsInline
                    src={URL_VIDEO + video.video}/>
            </Col>
        );
    }

    function gerarColunas(linha){
        let items = [];
        props.video.slice(((linha - 1) * 2), (linha * 2)).map(video => 
            items.push(gerarColuna(video))
        )
        return items;
    }

    function gerarLinha(linha){
        return (
            <Row className="mb-4"
                key={linha}>
                {gerarColunas(linha)}
            </Row>
        );
    }


    function gerarLinhas(){
        const numLinhas = Math.ceil(props.video.length / 2);
        let items = [];
        for(let linha = 1; linha <= numLinhas; linha++ ){
            items.push(gerarLinha(linha))
        }
        return items;
    }

    return (
        <LightgalleryProvider>
            {gerarLinhas()}
        </LightgalleryProvider>
    );

}

GaleriaVideo.propTypes = {
    video: PropTypes.array.isRequired,
}

export default GaleriaVideo;