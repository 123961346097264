import { 
    Container, 
    Nav, 
    Navbar,
    NavDropdown,
    Offcanvas,
} from 'react-bootstrap';
import * as constants from './constants';

function Menu() {
    return ( 
        <Navbar 
            expand="md" 
            bg="primary" 
            variant="dark" >
            <Container 
                fluid="md">
                <Navbar.Toggle 
                    aria-controls="offcanvasNavbar-expand-md" />
                <Navbar.Offcanvas
                    id="offcanvasNavbar-expand-md"
                    aria-labelledby="offcanvasNavbarLabel-expand-md"
                    placement="end">
                    <Offcanvas.Header 
                        closeButton>
                        <Offcanvas.Title 
                            id="offcanvasNavbar-expand-md">
                            Instituto Itaquerê
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav 
                            className="justify-content-center flex-grow-1 pe-3" >
                            <Nav.Link 
                                href={constants.BASE_NAME + '/'} 
                                className="px-5 active"
                                style={{ 
                                    fontWeight: 'bold' }}>
                                Início
                            </Nav.Link>
                            <Nav.Link 
                                href={constants.BASE_NAME + '/quem-somos'}
                                className="px-5 active"
                                style={{ 
                                    fontWeight: 'bold' }}>
                                Quem Somos
                            </Nav.Link>
                            <NavDropdown
                                title={
                                    <span 
                                        className="px-5"
                                        style={{ 
                                            fontWeight: 'bold',
                                            color: 'white'
                                                }}>
                                            Projetos
                                        </span>
                                    }
                                id="nav-dropdown"
                                >
                                <NavDropdown.Item 
                                    href={constants.BASE_NAME + '/casa-da-leitura'}
                                    className="px-5"
                                    style={{ 
                                        fontWeight: 'bold',
                                        color: 'blue' }}>
                                    Casa da Leitura
                                </NavDropdown.Item>
                                <NavDropdown.Item 
                                    href={constants.BASE_NAME + '/cecoi'}
                                    className="px-5"
                                    style={{ 
                                        fontWeight: 'bold',
                                        color: 'blue' }}>
                                    Cecoi
                                </NavDropdown.Item>
                                <NavDropdown.Item 
                                    href={constants.BASE_NAME + '/horta-itaquere'}
                                    className="px-5"
                                    style={{ 
                                        fontWeight: 'bold',
                                        color: 'blue' }}>
                                    Horta Itaquerê
                                </NavDropdown.Item>
                                <NavDropdown.Item 
                                    href={constants.BASE_NAME + '/projetos-financiados'}
                                    className="px-5"
                                    style={{ 
                                        fontWeight: 'bold',
                                        color: 'blue' }}>
                                    Projetos Financiados
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link 
                                href={constants.BASE_NAME + '/noticias'}
                                className="px-5 active"
                                style={{ 
                                    fontWeight: 'bold' }}>
                                Notícias
                            </Nav.Link>
                            <Nav.Link 
                                href={constants.BASE_NAME + '/transparencia'}
                                className="px-5 active"
                                style={{ 
                                    fontWeight: 'bold' }}>
                                Transparência
                            </Nav.Link>
                            <Nav.Link 
                                href={constants.BASE_NAME + '/contato'}
                                className="px-5 active"
                                style={{ 
                                    fontWeight: 'bold' }}>
                                Contato
                            </Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}

export default Menu;
