import {
    useState,
    useEffect,
} from 'react';
import Cabecalho from '../../utils/cabecalho';
import Menu from '../../utils/menu';
import Rodape from '../../utils/rodape';
import { 
    Container, 
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import {
    useParams
} from "react-router-dom";
import axios from 'axios';
import GaleriaNoticia from './galeira-noticia';
import GaleriaVideo from './galeira-video';
import * as constants from '../../utils/constants';

function Noticia() {

    const URL_IMG = constants.URL_BASE + constants.LOCAL_IMG;
    const URL_NOTICIA = constants.URL_BASE + constants.NOTICIA + '/';
    const URL_LISTAR_GALERIA = constants.URL_BASE + constants.GALERIA + '/';
    const URL_LISTAR_VIDEO = constants.URL_BASE + constants.VIDEO + '/';

    const [noticia, setNoticia] = useState([]);
    const [galeria, setGaleria] = useState([]);
    const [video, setVideo] = useState([]);
    const [carregarNoticia, setCarregarNoticia] = useState(true);
    const { id } = useParams();
    const [exibirGaleria, setExibirGaleria] = useState(false);
    const [exibirVideo, setExibirVideo] = useState(false);

    useEffect(() => {

        async function obterNoticia(){
            let { data } = await axios.get(URL_NOTICIA + id);
            setNoticia(data.data);
        }

        async function obterGaleria(){
            let { data } = await axios.get(URL_LISTAR_GALERIA + id);
            setGaleria(data.data);
            if(data.data.length > 0){
                setExibirGaleria(true);
            } else {
                setExibirGaleria(false);
            }
        }

        async function obterVideo(){
            let { data } = await axios.get(URL_LISTAR_VIDEO + id);
            setVideo(data.data);
            if(data.data.length > 0){
                setExibirVideo(true);
            } else {
                setExibirVideo(false);
            }
        }

        if(carregarNoticia){
            obterNoticia();
            obterGaleria();
            obterVideo();
            setCarregarNoticia(false);
        }
    }, [carregarNoticia]);

    return ( 
        <div>
            <Cabecalho />
            <Menu />
            <Container fluid="md" >
                <Row>
                    <Col md={12}
                        className="display-3 mt-4"
                        style={{ 
                            fontWeight: 'bold',
                            textColor: 'blue',
                            marginTop: '2rem', }}>
                        {noticia.titulo}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}
                        style={{
                            marginBottom: '1.5rem' }}>
                        {noticia.dthr}
                    </Col>
                </Row>
                <Row>
                    <Col 
                        md={12}
                        className="text-center">
                        <Image 
                            src={URL_IMG + noticia.capa}
                            thumbnail />
                    </Col>
                </Row>
                <Row>
                    <Col 
                        md={12}
                        className="mt-4"
                        style={{whiteSpace: "pre-wrap"}}>
                        {noticia.conteudo}
                    </Col>
                </Row>
                <Row>
                    <Col md={12}
                        className='display-5 mt-4'
                        style={{ 
                            fontWeight: 'bold' }}>
                        {exibirGaleria ? 'Galeria de Fotos' : ''}
                    </Col>
                </Row>
                <GaleriaNoticia 
                    className={exibirGaleria ? '' : 'hidden'}
                    galeria={galeria} />
                <Row>
                    <Col 
                        md={12}
                        className="display-5 mt-4"
                        style={{ 
                            fontWeight: 'bold' }}>
                        {exibirVideo ? 'Galeria de Videos' : ''}
                    </Col>
                </Row>
                <GaleriaVideo
                    className={exibirVideo ? '' : 'hidden'}
                    video={video} />
            </Container>
            <Rodape />
        </div>
     );
}

export default Noticia;