import PropTypes from 'prop-types';
import { 
    Col,
    Row,
    Image
} from 'react-bootstrap';
import { LightgalleryProvider } from 'react-lightgallery';
import { LightgalleryItem } from 'react-lightgallery';
import * as constants from '../../utils/constants';

function GaleriaNoticia(props) {

    const URL_IMG = constants.URL_BASE + constants.LOCAL_IMG;

    function gerarColuna(imagem){
        return ( 
            <Col md={3}
                key={imagem.codigo}>
                <LightgalleryItem src={URL_IMG + imagem.image}>
                    <Image
                        key={imagem.codigo}
                        variant="top" 
                        src={URL_IMG + imagem.image}
                        thumbnail 
                        style={{ cursor: 'pointer' }}/>
                </LightgalleryItem>
            </Col>
        );
    }

    function gerarColunas(linha){
        let items = [];
        props.galeria.slice(((linha - 1) * 4), (linha * 4)).map(image => 
            items.push(gerarColuna(image))
        )
        return items;
    }

    function gerarLinha(linha){
        return (
            <Row className="mb-4"
                key={linha}>
                {gerarColunas(linha)}
            </Row>
        );
    }


    function gerarLinhas(){
        const numLinhas = Math.ceil(props.galeria.length / 4);
        let items = [];
        for(let linha = 1; linha <= numLinhas; linha++ ){
            items.push(gerarLinha(linha))
        }
        return items;
    }

    return (
        <LightgalleryProvider>
            {gerarLinhas()}
        </LightgalleryProvider>
    );

}

GaleriaNoticia.propTypes = {
    galeria: PropTypes.array.isRequired,
}

export default GaleriaNoticia;