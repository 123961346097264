import Cabecalho from '../../utils/cabecalho';
import Menu from '../../utils/menu';
import Rodape from '../../utils/rodape';
import { 
    Container, 
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import GaleriaQuemSomos from './galeira-quem-somos';
import image from '../../img/quem-somos.jpg';

function QuemSomos() {
    return ( 
        <div>
            <Cabecalho />
            <Menu />
            <Container fluid="md" >
                <Row>
                    <Col md={12}
                        className="display-3 mt-4"
                        style={{ 
                            fontWeight: 'bold',
                            textColor: 'blue',
                            marginTop: '2rem',
                            marginBottom: '1.5rem' }}>
                        Quem Somos
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mb-4">
                    <Image
                        variant="top" 
                        src={image}
                        thumbnail />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <h2>MISSÃO</h2>
                        <p>Atuar na promoção social, desenvolvendo, assessorando e apoiando 
                            tecnicamente programas de atendimento a crianças e adolescente, 
                            visando a sua integração social, educação e saúde, defendendo seus 
                            direitos e garantindo-lhes a vivência da cidadania. </p>
                        <br />
                        <h2>VISÃO</h2>
                        <p>Tornar-se referência através da responsabilidade social, ampliando 
                            sua capacidade de atendimento, com abertura de vagas, e da 
                            diversificação de novos cursos e atividades, se expandindo para 
                            crianças e adolescentes pertencentes a outras entidades da região.</p>
                        <br />
                        <h2>HISTÓRICO DA INSTITUIÇÃO</h2>
                        <p>O Instituto de Desenvolvimento Social Itaquerê é uma Organização 
                            da Sociedade Civil, que atende a comunidade de Nova Europa e os 
                            munícipes das cidades de Tabatinga, Curupá e Gavião Peixoto.</p>
                        <p>Sua finalidade é a promoção da assistência social, da segurança 
                            alimentar e nutricional, do voluntariado, da saúde, do combate à 
                            obreza, da preservação do meio ambiente, da educação e da cultura.</p>
                        <p>A ideia de desenvolver um projeto social surgiu em 22 de fevereiro 
                            de 2000, com o intuito de reativar o grupo escolar já existente na 
                            fazenda Itaquerê, através da realização de cursos, encontros e atividades 
                            educativas, visando aperfeiçoar o conhecimento em diversas áreas. 
                            Para a realização deste trabalho foi feito um levantamento de pessoas 
                            que tinham interesse em participar do projeto voluntariamente, oferecendo 
                            1 ou 2 horas por semana de seu trabalho, fora do horário de serviço, 
                            para este projeto.</p>
                        <p>O andamento e sucesso do projeto, assim como o número de atividades 
                            realizadas, dependeu exclusivamente do número de pessoas voluntariamente 
                            envolvidas e comprometias com a sua realização.</p>
                        <p>Como a base do projeto é o trabalho voluntário, todas as atividades 
                            e cursos sempre foram gratuitos, sendo a empresa, Usina Santa Fé, 
                            responsável pelo transporte, pelo lanche e por todas as outras 
                            despesas psicopedagógicas.</p>
                        <p>O projeto só é, de fato, viabilizado em junho de 2000, com a 
                            fundação do CECOI – Centro de Convivência Itaquerê, que por 14 anos 
                            desempenhou diversas ações sociais. Em dezembro de 2014 é realizada 
                            a assembleia de constituição do atual Instituto de Desenvolvimento 
                            Itaquerê, que nasceu para substituir o antigo centro, e dar continuidade 
                            aos seus projetos sociais.</p>
                        <p>Ao longo dos 22 anos de história, o CECOI já beneficiou mais de 
                            8.000 crianças e adolescentes que passaram por ele, uma média de 
                            400 crianças por ano. Cerca de 300 voluntários já doaram seu 
                            tempo e contribuíram para o crescimento e consolidação do projeto.</p>
                        <p>O Instituto Itaquerê desenvolve suas ações através de 2 grandes 
                            projetos, o CECOI – Centro de Convivência Itaquerê e a 
                            Biblioteca Casa da Leitura Itaquerê. Desenvolve ainda o Projeto Horta 
                            Itaquerê, parcerias, e a execução de projetos financiados pelo FMDCA – 
                            Nova Europa.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}
                        className="display-5 mt-4"
                        style={{ 
                            fontWeight: 'bold' }}>
                        Galeria de Fotos
                    </Col>
                </Row>
                <GaleriaQuemSomos />
            </Container>
            <Rodape />
        </div>
    );
}

export default QuemSomos;