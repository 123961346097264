import Cabecalho from '../../utils/cabecalho';
import Menu from '../../utils/menu';
import Rodape from '../../utils/rodape';
import { 
    Container, 
    Row,
    Col,
} from 'react-bootstrap';

function Contato() {
    return ( 
        <div>
            <Cabecalho />
            <Menu />
            <Container fluid="md" >
                <Row>
                    <Col md={12}
                        className="display-3 mt-4"
                        style={{ 
                            fontWeight: 'bold',
                            textColor: 'blue',
                            marginTop: '2rem',
                            marginBottom: '1.5rem' }}>
                        Contato
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>E-mail:</p>
                        <p><b>institutoitaquere@institutoitaquere.org.br</b></p>
                        <br />
                        <p>Telefone:</p>
                        <p><b>(16) 3387-9990</b></p>
                        <br />
                        <p>WhatsApp:</p>
                        <p><b>(16) 99712-5559</b></p>
                    </Col>
                </Row>
            </Container>
            <Rodape />
        </div>
     );
}

export default Contato;