import PropTypes from 'prop-types';
import { 
    Col,
    Card,
    Row,
} from 'react-bootstrap';
import * as constants from '../../utils/constants';

function ItensNoticias(props) {

    const URL_IMG = constants.URL_BASE + constants.LOCAL_IMG;

    function formatDate(data) {
        
        var ano  = data.slice(0, 4);
        var mes  = data.slice(5, 7);
        var dia  = data.slice(8, 10);
        var hora  = data.slice(11, 16);
      
        return dia + '/' + mes + '/' + ano + ' ' + hora;
    }

    function gerarColuna(noticia){
        return ( 
            <Col 
                md={4} 
                key={noticia.codigo}>
                <a href={'/noticia/' + noticia.codigo}
                    style={{ 
                        textDecoration: 'none', 
                        color: '#000' }} >
                    <Card
                        key={noticia.codigo}>
                        <Card.Img 
                            variant="top" 
                            src={URL_IMG + noticia.capa}
                            style={{ 
                                minHeight: '20rem' }}  />
                        <Card.Body>
                            <Card.Title
                                style={{ 
                                    fontWeight: 'bold',
                                    fontSize: '1.8rem', }}>
                                {noticia.titulo}
                            </Card.Title>
                            <Card.Text>
                                {formatDate(noticia.dthr)}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </a>
            </Col>
        );
    }

    function gerarColunas(linha){
        let items = [];
        props.noticias.slice(((linha - 1) * 3), (linha * 3)).map(noticia => 
            items.push(gerarColuna(noticia))
        )
        return items;
    }

    function gerarLinha(linha){
        return (
            <Row 
                className="mb-4" 
                key={linha}>
                {gerarColunas(linha)}
            </Row>
        );
    }


    function gerarLinhas(){
        const numLinhas = Math.ceil(props.noticias.length / 3);
        let items = [];
        for(let linha = 1; linha <= numLinhas; linha++ ){
            items.push(gerarLinha(linha))
        }
        return items;
    }

    return (
        gerarLinhas()
    );

}

ItensNoticias.propTypes = {
    noticias: PropTypes.array.isRequired,
}

export default ItensNoticias;