import {
    useState,
    useEffect,
} from 'react';
import { 
    Container, 
    Row,
    Col,
} from 'react-bootstrap';
import axios from 'axios';
import Cabecalho from '../../utils/cabecalho';
import Menu from '../../utils/menu';
import Rodape from '../../utils/rodape';
import ItensNoticias from './itens-noticias';
import * as constants from '../../utils/constants';

function Contato() {

    const URL_LISTAR_NOTICIA = constants.URL_BASE + constants.NOTICIA;

    const [noticias, setNoticias] = useState([]);
    const [carregarNoticias, setCarregarNoticias] = useState(true);

    useEffect(() => {

        async function obterNoticias(){
            try{
                let ordem = 'codigo DESC';
                const params = `?page=1&countpage=1000&order=${ordem}&filter=`;
                let { data } = await axios.get(URL_LISTAR_NOTICIA + params);
                if(data.status==='success'){
                    setNoticias(data.data);
                } else {
                    setNoticias([]);
                }
            } catch(err){
                setNoticias([]);
            }
        }

        if(carregarNoticias){
            obterNoticias();
            setCarregarNoticias(false);
        }
    }, [carregarNoticias]);

    return ( 
        <div>
            <Cabecalho />
            <Menu />
            <Container fluid="md" >
                <Row>
                    <Col md={12}
                        className="display-3 mt-4"
                        style={{ 
                            fontWeight: 'bold',
                            textColor: 'blue',
                            marginTop: '2rem',
                            marginBottom: '1.5rem' }}>
                        Notícias
                    </Col>
                </Row>
                <ItensNoticias
                    noticias={noticias} />
            </Container>
            <Rodape />
        </div>
     );
}

export default Contato;