import Cabecalho from '../../utils/cabecalho';
import Menu from '../../utils/menu';
import Rodape from '../../utils/rodape';
import { 
    Container, 
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import GaleriaProjetosFinanciados from './galeira-projetos-financiados';
import image1 from '../../img/projeto-casa-da-leitura-itaquere.jpg';
import image2 from '../../img/projeto-ita-esportivo.jpg';
import image3 from '../../img/projeto-usina-esportiva-itaquere.jpg';

function ProjetosFinanciados() {
    return ( 
        <div>
            <Cabecalho />
            <Menu />
            <Container fluid="md" >
                <Row>
                    <Col md={12}
                        className="display-3 mt-4"
                        style={{ 
                            fontWeight: 'bold',
                            textColor: 'blue',
                            marginTop: '2rem',
                            marginBottom: '1.5rem' }}>
                        PROJETOS FINANCIADOS
                    </Col>
                </Row>
                <Row>
                    <Col md={12}
                        className="display-5 mb-3">
                        Projeto Usina Esportiva Itaquerê
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mb-4">
                    <Image
                        variant="top" 
                        src={image3}
                        thumbnail />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>O projeto Usina Esportiva Itaquerê foi uma parceria do 
                            Instituto Itaquerê com o RED Instituto, da cidade de Guaíra, 
                            incentivado pela Lei Paulista de Incentivo ao Esporte, tendo 
                            por ferramenta a modalidade do futebol e vôlei, visando atender 
                            250 crianças, jovens e adolescentes de ambos os sexos, com 
                            idades entre 07 e 17 anos. Para o projeto foram contratados 3 
                            professores e 2 estagiários que deram todo o suporte necessário 
                            para sua realização.</p>
                        <p>O Projeto Usina Esportiva Itaquerê – Ano I iniciou suas 
                            atividades em agosto/2017 e encerrou em junho/2018. O Ano II 
                            teve início em outubro/2018 e término em agosto/2019.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}
                        className="display-5 mt-4"
                        style={{ 
                            fontWeight: 'bold' }}>
                        Galeria de Fotos
                    </Col>
                </Row>
                <GaleriaProjetosFinanciados
                    urlImg={'http://www.institutoitaquere.org.br/img/projeto-usina-esportiva-itaquere-'}
                    qtdeImg={14} />
                <Row>
                    <Col md={12}
                        className="display-5 mb-3">
                        Projeto Casa da Leitura Itaquerê
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mb-4">
                    <Image
                        variant="top" 
                        src={image1}
                        thumbnail />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>O projeto Casa da Leitura Itaquerê foi financiado pelo FMDCA 
                            – Nova Europa (Fundo Municipal de Direitos da Criança e 
                            do Adolescente) e visou oferecer através de atividades 
                            educacionais e pedagógicas, conteúdos fundamentais ao seu 
                            desenvolvimento e formação cidadã, contribuindo para o estimulo 
                            no hábito pela leitura, ampliando conhecimentos, tornando a 
                            leitura uma prática prazerosa e melhorando a escrita e a leitura. 
                            O projeto foi realizado no Instituto Itaquerê (CECOI e Biblioteca 
                            Casa da Leitura Itaquerê) e em todas as escolas da rede 
                            municipal de ensino fundamental. </p>
                        <p>O projeto Casa da Leitura Itaquerê foi financiado pelo FMDCA 
                            – Nova Europa (Fundo Municipal de Direitos da Criança e do 
                            Adolescente)  e visou oferecer através de atividades educacionais 
                            e pedagógicas, conteúdos fundamentais ao seu desenvolvimento e 
                            formação cidadã, contribuindo para o estimulo no hábito pela 
                            leitura, ampliando conhecimentos, tornando a leitura uma prática 
                            prazerosa e melhorando a escrita e a leitura. O projeto foi 
                            realizado no Instituto Itaquerê (CECOI e Biblioteca Casa da 
                            Leitura Itaquerê) e em todas as escolas da rede municipal de 
                            ensino fundamental. </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}
                        className="display-5 mt-4"
                        style={{ 
                            fontWeight: 'bold' }}>
                        Galeria de Fotos
                    </Col>
                </Row>
                <GaleriaProjetosFinanciados
                    urlImg={'http://www.institutoitaquere.org.br/img/projeto-casa-da-leitura-itaquere-'}
                    qtdeImg={14} />
                <Row>
                    <Col md={12}
                        className="display-5 mb-3">
                        Projeto Ita Esportivo
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mb-4">
                    <Image
                        variant="top" 
                        src={image2}
                        thumbnail />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>O Projeto Ita Esportivo é um projeto em parceria com a 
                            Prefeitura Municipal de Nova Europa e financiado pelo FMDCA 
                            (Fundo Municipal de Direitos da Criança e do Adolescente). 
                            Teve início em agosto/2022 e tem previsão de término em 
                            dezembro/2022.</p>
                        <p>Visa atender 80 crianças e adolescentes, de ambos os sexos, 
                            com faixa etária entre 12 a 17 anos, residentes na cidade 
                            de Nova Europa e que estejam com a vacinação do Covid-19 
                            em dia.</p>
                        <p>O Projeto <b>Ita Esportivo</b> vem para dar continuidade ao 
                            <b>Projeto Usina Esportiva Itaquerê</b>, que foi uma parceria 
                            com o RED Instituto, da cidade de Guaíra, que em 2 anos 
                            atendeu mais de 400 alunos de Nova Europa e região.</p>
                        <p>Tem como principal objetivo oferecer a oportunidade de 
                            aprendizagem dos fundamentos do futebol, contribuindo para 
                            o desenvolvimento psico-físico-social de crianças e 
                            adolescentes, através da prática do futebol de forma 
                            orientada e com acompanhamento técnico.</p>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}
                        className="display-5 mt-4"
                        style={{ 
                            fontWeight: 'bold' }}>
                        Galeria de Fotos
                    </Col>
                </Row>
                <GaleriaProjetosFinanciados
                    urlImg={'http://www.institutoitaquere.org.br/img/projeto-ita-esportivo-'}
                    qtdeImg={9} />
            </Container>
            <Rodape />
        </div>
     );
}

export default ProjetosFinanciados;