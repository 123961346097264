import './instituto-itaquere.css';
import { 
  BrowserRouter, 
  Routes, 
  Route 
} from 'react-router-dom';
import Home from './components/home/home';
import QuemSomos from './components/quem-somos/quem-somos';
import CasaDaLeitura from './components/casa-da-leitura/casa-da-leitura';
import Cecoi from './components/cecoi/cecoi';
import HortaItaquere from './components/horta-itaquere/horta-itaquere';
import ProjetosFinanciados from './components/projetos-financiados/projetos-financiados';
import Noticias from './components/noticia/noticias';
import Noticia from './components/noticia/noticia';
import Transparencia from './components/transparencia/transparencia';
import Contato from './components/contato/contato';
import * as constants from './utils/constants';

function InstitutoItaquere() {
  return (
    <BrowserRouter basename={constants.BASE_NAME}>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/quem-somos" element={<QuemSomos />} exact />
        <Route path="/casa-da-leitura" element={<CasaDaLeitura />} exact />
        <Route path="/cecoi" element={<Cecoi />} exact />
        <Route path="/horta-itaquere" element={<HortaItaquere />} exact />
        <Route path="/projetos-financiados" element={<ProjetosFinanciados />} exact />
        <Route path="/noticias" element={<Noticias />} exact />
        <Route path="/noticia/:id" element={<Noticia />} exact />
        <Route path="/transparencia" element={<Transparencia />} exact />
        <Route path="/contato" element={<Contato />} exact />
      </Routes>
    </BrowserRouter>
  );
}

export default InstitutoItaquere;
